import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '@/_models';

import {AppSettings} from '@/_config';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public currentUserAccount: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        // return this.http.post<any>(`https://hireco.live:5150/users/authenticate`, { username, password })
        return this.http.post<any>(AppSettings.API_URL+'/users/authenticate', { username, password })
            .pipe(map(data => {

                // login successful if there's a jwt token in the response
                console.log("raw data", data);
                var user = data.user;
                var account_id = user.account_id;
                console.log("account_id", account_id);

                /*
                [ { id_c: 'a7e555f8-2ad4-1694-2489-51cb651d38dc',
                  password_c: '',
                  twitter_name_c: '',
                  twitter_password_c: '',
                  linkedin_name_c: '',
                  nickname_c: 'Scalastica',
                  cloakname_c: 'Scalastica Cloak',
                  profile_photo_c: '',
                  role_c: '2388a2b4-13b2-21ef-fe07-52732991fbfc',
                  default_name_type_c: '7805a076-80e7-8b7c-2b5f-4e0e757c1d1d',
                  social_network_name_type_c: '178af3ac-57f1-63d9-60b0-4e0e7534ec7b',
                  friends_name_type_c: '7805a076-80e7-8b7c-2b5f-4e0e757c1d1d',
                  jjwg_maps_lng_c: null,
                  jjwg_maps_lat_c: null,
                  jjwg_maps_geocode_status_c: '',
                  jjwg_maps_address_c: '',
                  cmn_languages_id_c: '7fe147af-2c8f-6927-f89a-4d43b3374e4f',
                  cmn_countries_id_c: '17db96c2-1433-66d7-516f-4c8f3b8bf6b4' } ],

                */

                //console.log("Token in auth: ",user.token);
                //console.log("Role in auth: ",user.role);
                //console.log("User ID in auth: ",user.user_id);

                if (user.user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user.user));
                    localStorage.setItem('currentUserAccount', JSON.stringify(account_id));
                    //localStorage.setItem('currentUser', user);
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    register(email:any, password:any){
        return this.http.post<any>(AppSettings.API_URL+'/user/add/',{email, password});
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
