export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'BUSINESS'        : {
                'TITLE': 'Business',
                'BADGE': '25'
            },
            'CALENDAR'        : {
                'TITLE': 'Calendar',
                'BADGE': '25'
            },
            'CONTACTS'        : {
                'TITLE': 'Contacts',
                'BADGE': '25'
            },
            'DASHBOARDS'        : {
                'TITLE': 'Dashboards',
                'BADGE': '25'
            },
            'ECOMMERCE'        : {
                'TITLE': 'E-Commerce',
                'BADGE': '25'
            },
            'ORGANISATIONS'        : {
                'TITLE': 'Organisations',
                'BADGE': '25'
            },
            'PRODUCTS'        : {
                'TITLE': 'Products',
                'BADGE': '25'
            },
        }
    }
};
