import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@/_models';

import {AppSettings} from '@/_config';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(AppSettings.API_URL+'/users');
    }

    getById(id: number) {
        return this.http.get<User>(AppSettings.API_URL+'/users/'+id);
    }
}
