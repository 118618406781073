import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

// used to create fake backend
//import { fakeBackendProvider } from './_helpers';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { routing } from './app.routing';
// import { OrganisationsModule } from 'app/main/Organisations/Organisations.module';
// import { OrganisationsComponent } from './main/organisations/organisations.component';

import { LoginModule } from 'app/login/login.module';



import { JwtInterceptor, ErrorInterceptor } from './_helpers';
// import { HomeComponent } from './home';
import { AdminComponent } from './admin';
// import { LoginComponent } from 'app/login/login';
//
// const appRoutes: Routes = [
    // {
    //     path        : 'apps',
    //     loadChildren: './main/apps/apps.module#AppsModule'
    // },
    // {
    //     path        : 'organisations',
    //     loadChildren: './main/organisations/organisations.module#OrganisationsModule'
    // },
    // {
    //     path      : '**',
    //     redirectTo: 'apps/business/business.module#BusinessOrganisationsModule'
    // }
    // {
    //     path      : '**',
    //     redirectTo: 'apps/dashboards/analytics'
    // }
// ];

@NgModule({
  imports     : [
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      // RouterModule.forRoot(appRoutes),

      TranslateModule.forRoot(),

      // Material moment date module
      MatMomentDateModule,

      // Material
      MatButtonModule,
      MatIconModule,

      // Fuse modules
      FuseModule.forRoot(fuseConfig),
      FuseProgressBarModule,
      FuseSharedModule,
      FuseSidebarModule,
      FuseThemeOptionsModule,

      // App modules
      LayoutModule,
      // OrganisationsModule
      ReactiveFormsModule,
      LoginModule,
      routing
  ],
    declarations: [
        AppComponent,
        // HomeComponent,
        AdminComponent
        //LoginComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // provider used to create fake backend
        //fakeBackendProvider
      ],

    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
