import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
          {
              id       : 'profile',
              title    : 'Profile',
              translate: 'NAV.BUSINESS',
              type     : 'collapsable',
              icon     : 'shopping_cart',
              url      : '/apps/home'
          },
          {
              id       : 'hireco',
              title    : 'HIRECO',
              translate: 'NAV.BUSINESS',
              type     : 'collapsable',
              icon     : 'shopping_cart',
              children : [
                  {
                      id        : 'events',
                      title     : 'Events',
                      type      : 'item',
                      url       : '/apps/hireco/events',
                      exactMatch: true
                  },
                ]
          },
          {
              id       : 'business',
              title    : 'Business',
              translate: 'NAV.BUSINESS',
              type     : 'collapsable',
              icon     : 'shopping_cart',
              children : [
                  {
                      id        : 'organisations',
                      title     : 'Organisations',
                      type      : 'item',
                      url       : '/apps/business/organisations',
                      exactMatch: true
                  },
                  // {
                  //     id        : 'organisationDetail',
                  //     title     : 'Organisation Detail',
                  //     type      : 'item',
                  //     url       : '/apps/business/organisationDetail',
                  //     exactMatch: true
                  // },
                  {
                      id        : 'accounts',
                      title     : 'Accounts',
                      type      : 'item',
                      url       : '/apps/business/accounts',
                      exactMatch: true
                  },
                  // {
                  //     id        : 'accountDetail',
                  //     title     : 'Account Detail',
                  //     type      : 'item',
                  //     url       : '/apps/business/accountDetail',
                  //     exactMatch: true
                  // },
                ]
            },
            {
                id       : 'sounds',
                title    : 'Sounds',
                translate: 'NAV.SOUNDS',
                type     : 'collapsable',
                icon     : 'volume_up',
                children : [
                    {
                        id        : 'recorder',
                        title     : 'Recorder',
                        type      : 'item',
                        url       : '/apps/sounds/recorder',
                        exactMatch: true
                    },
                    {
                        id        : 'recordings',
                        title     : 'Recordings',
                        type      : 'item',
                        url       : '/apps/sounds/recordings',
                        exactMatch: true
                    },
                    // {
                    //     id        : 'recording',
                    //     title     : 'Recording',
                    //     type      : 'item',
                    //     url       : '/apps/sounds/recording',
                    //     exactMatch: true
                    // }
                ]
            },
          // {
          //     id       : 'dashboards',
          //     title    : 'Dashboards',
          //     translate: 'NAV.DASHBOARDS',
          //     type     : 'collapsable',
          //     icon     : 'dashboard',
          //     children : [
          //         {
          //             id   : 'analytics',
          //             title: 'Analytics',
          //             type : 'item',
          //             url  : '/apps/dashboards/analytics'
          //         },
          //         {
          //             id   : 'project',
          //             title: 'Project',
          //             type : 'item',
          //             url  : '/apps/dashboards/project'
          //         }
          //     ]
          // },
          // {
          //     id       : 'contacts',
          //     title    : 'Contacts',
          //     translate: 'NAV.CONTACTS',
          //     type     : 'item',
          //     icon     : 'account_box',
          //     url      : '/apps/contacts'
          // },
          {
              id       : 'calendar',
              title    : 'Calendar',
              translate: 'NAV.CALENDAR',
              type     : 'item',
              icon     : 'today',
              url      : '/apps/calendar'
          },
          {
              id       : 'e-commerce',
              title    : 'E-Commerce',
              translate: 'NAV.ECOMMERCE',
              type     : 'collapsable',
              icon     : 'shopping_cart',
              children : [
                  // {
                  //     id        : 'organisations',
                  //     title     : 'Organisations',
                  //     type      : 'item',
                  //     url       : '/apps/e-commerce/organisations',
                  //     exactMatch: true
                  // },
                  // {
                  //     id        : 'products',
                  //     title     : 'Products',
                  //     type      : 'item',
                  //     url       : '/apps/e-commerce/products',
                  //     exactMatch: true
                  // },
                  // {
                  //     id        : 'productDetail',
                  //     title     : 'Product Detail',
                  //     type      : 'item',
                  //     url       : '/apps/e-commerce/products/1/printed-dress',
                  //     exactMatch: true
                  // },
                  // {
                  //     id        : 'orders',
                  //     title     : 'Orders',
                  //     type      : 'item',
                  //     url       : '/apps/e-commerce/orders',
                  //     exactMatch: true
                  // },
                  // {
                  //     id        : 'orderDetail',
                  //     title     : 'Order Detail',
                  //     type      : 'item',
                  //     url       : '/apps/e-commerce/orders/1',
                  //     exactMatch: true
                  // },
                  {
                      id        : 'transactions',
                      title     : 'Transactions',
                      type      : 'item',
                      url       : '/apps/e-commerce/transactions',
                      exactMatch: true
                  },
                  // {
                  //     id        : 'transactionDetail',
                  //     title     : 'Transaction Detail',
                  //     type      : 'item',
                  //     url       : '/apps/e-commerce/transactions/',
                  //     exactMatch: true
                  // },
                  {
                      id        : 'coas',
                      title     : 'COA',
                      type      : 'item',
                      url       : '/apps/e-commerce/coas',
                      exactMatch: true
                  },
                  {
                      id        : 'paymentmethods',
                      title     : 'Payment Methods',
                      type      : 'item',
                      url       : '/apps/e-commerce/paymentmethods',
                      exactMatch: true
                  },
              ]
          },
        ]
    }
];
