import { Routes, RouterModule } from '@angular/router';

// import { HomeComponent } from './home';
// import { AdminComponent } from './admin';
// import { RecordingsComponent } from './main/apps/sounds/recordings/recordings.component';
// import { SoundsModule } from './main/apps/sounds';
// import { LoginComponent } from './login';
import { AuthGuard } from './_guards';
import { Role } from './_models';

const appRoutes: Routes = [
    { path: '', redirectTo: 'apps', pathMatch: 'full' },
    { path: 'login', loadChildren: () => import('app/login/login.module').then(m => m.LoginModule) },
    { path: 'register', loadChildren: () => import('app/register/register.module').then(m => m.RegisterModule)},
    { path : 'apps', loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    //     canActivate: [AuthGuard],
    //     data: { roles: [Role.User] }
    }
    // {
    //     path        : 'pages',
    //     loadChildren: './main/pages/pages.module#PagesModule'
    // },
    // {
    //     path: 'home',
    //     component: HomeComponent,
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'sounds',
    //     module: SoundsModule,
    //     canActivate: [AuthGuard],
    //     data: { roles: [Role.User] }
    // },
    // {
    //     path        : 'apps',
    //     loadChildren: './main/apps/apps.module#AppsModule'
    // },
    // {
    //     path: 'sounds/recordings',
    //     component: RecordingsComponent,
    //     canActivate: [AuthGuard],
    //     data: { roles: [Role.User] }
    // },
    // {
    //     path: 'admin',
    //     component: AdminComponent,
    //     canActivate: [AuthGuard],
    //     // data: { roles: [Role.Admin] }
    // },

    // otherwise redirect to home
    // { path: '**', redirectTo: 'apps' }
];

export const routing = RouterModule.forRoot(appRoutes);
